import React from 'react'
import './ph.css'
import work from '../wrench-solid.svg'

export default function Ph() {
  return (
    <div className='bg  d-flex justify-content-center align-items-center '>
      <div className= 'main-div d-flex align-items-center justify-content-center '>
      <div>
        <img width={100} height={100} alt='construction' src={work} />
      </div>

      </div>


    </div>
  )
}
